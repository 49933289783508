
export default {
    name: "BootomFooter",
    components: {
    Link: () => import("@/components/elements/Link.vue"),
  },
  computed: {
    isObfuscated() {
      return this.$route.path != "/";
    },
  },

}
